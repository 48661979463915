import { getPagePath } from '../utils/pages'
import { PageId } from './pages'

export type Announcement = {
  id: string
  title: string
  description: string
  href: string
  target?: string
  imageUrl: string
}

export type SiteWideAnnouncement = {
  id: string
  message: string
  startTimestamp: number
  endTimestamp: number
  href?: string
  severity?: 'high' | 'medium' | 'low'
  isDismissible?: boolean
}

export const ANNOUNCEMENTS: Announcement[] = [
  {
    id: 'derive-drv',
    title: 'DRV Has Arrived',
    description: 'Claim, stake and delegate your DRV airdrop now.',
    href: getPagePath({ page: PageId.Airdrop }),
    imageUrl: '/images/tokens/drv.png',
  },
  {
    id: 'op-rewards-10k',
    title: '10k OP Rewards Pool',
    description:
      'In addition to DRV rewards, traders can now earn up to 10,000 OP per week based on trading fees paid.',
    href: getPagePath({ page: PageId.Leaderboard }),
    imageUrl: '/images/tokens/op.png',
  },
]

export const SITE_WIDE_ANNOUNCEMENTS: SiteWideAnnouncement[] = [
  {
    id: 'derive-derby',
    message:
      '$DRV token launch is scheduled for Jan 15. Enjoy 5x trading rewards, 20k OP per week, 50% off perp fees and airdrop bonuses.',
    startTimestamp: new Date('2024-12-01').getTime(),
    endTimestamp: new Date('2025-01-01').getTime(),
    href: getPagePath({ page: PageId.DRV }),
    isDismissible: true,
  },
]
