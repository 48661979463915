import tryRequest, { GetRequestOptions } from './tryRequest'
import {
  PublicGetTransactionParamsSchema,
  PublicGetTransactionResponseSchema,
} from './types/public.get_transaction'

export default async function getTransactionStatus(
  params: PublicGetTransactionParamsSchema,
  options?: GetRequestOptions
): Promise<PublicGetTransactionResponseSchema> {
  return tryRequest<PublicGetTransactionParamsSchema, PublicGetTransactionResponseSchema>(
    '/public/get_transaction',
    params,
    { ...options, useGet: true }
  )
}
