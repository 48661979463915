import { ButtonProps } from '@lyra/core/components/Button'
import TrackButton from '@lyra/web/components/common/TrackButton'
import useAuth from '@lyra/web/hooks/useAuth'
import { useConnectModal } from '@rainbow-me/rainbowkit'
import { useEffect } from 'react'

export default function SignInButton({
  onChangeOpenModal,
  onPress,
  isDisabled,
  label,
  ...buttonProps
}: ButtonProps & { onChangeOpenModal?: (isOpen: boolean) => void }) {
  const { isAuthenticated } = useAuth()

  const { openConnectModal, connectModalOpen } = useConnectModal()

  useEffect(() => {
    onChangeOpenModal?.(connectModalOpen)
  }, [connectModalOpen, onChangeOpenModal])

  return (
    <TrackButton
      {...buttonProps}
      isDisabled={isAuthenticated || isDisabled}
      eventName={'sign-in-button'}
      label="Connect"
      onPress={async (e) => {
        if (onPress) {
          await onPress(e)
        }
        if (openConnectModal) {
          openConnectModal()
        } else {
          console.warn('already authenticated')
        }
      }}
    />
  )
}
