// nav
export const NAV_HEIGHT_DESKTOP = 60
export const NAV_MARQUEE_HEIGHT = 28
export const NAV_MOBILE_HEIGHT = 60
export const NAV_MOBILE_TABS_HEIGHT = 72

// SITE WIDE NOTICE
export const SITE_WIDE_NOTICE_HEIGHT = 40

// pages
export const MAX_PAGE_WIDTH = 2000
export const BANNER_PAGE_WIDTH = 1180

// trade layout
export const TRADE_HEADER_MARKET_SELECT_WIDTH = 330
export const TRADE_RIGHT_RAIL_WIDTH_DESKTOP = 330
export const TRADE_HEADER_MARKET_SECTION_HEIGHT = 80
export const TRADE_BODY_HEIGHT_DESTKOP = 700

// auctions page
export const AUCTIONS_BID_FORM_WIDTH_DESKTOP = 350

// portfolio page
export const PORTFOLIO_PAGE_WIDTH = 1200
export const PORTFOLIO_HEADER_HEIGHT = 260
export const PORTFOLIO_ACCOUNT_SUMMARY_WIDTH = 320

// perps page
export const PERPS_ORDERBOOK_WIDTH_DESKTOP = TRADE_RIGHT_RAIL_WIDTH_DESKTOP

// spot page
export const SPOT_CHART_HEIGHT_DESKTOP = 600
export const SPOT_ORDERBOOK_WIDTH_DESKTOP = TRADE_RIGHT_RAIL_WIDTH_DESKTOP

// settings pages
export const SETTINGS_MENU_WIDTH = 140
export const SETTINGS_PAGE_WIDTH = 960

// partner page
export const PARTNERS_PAGE_WIDTH = 1080

// snapshot page
export const SNAPSHOT_PAGE_WIDTH = 860

export const SECTION_SPINNER_SIZE = 64

// positions drawer
export const POSITIONS_DRAWER_HEADER_HEIGHT = 74
const POSITIONS_TABLE_HEADER_HEIGHT = 40
const POSITIONS_TABLE_ROW_HEIGHT = 62
const MIN_POSITIONS_DRAWER_ROWS = 8

export const POSITIONS_DRAWER_MIN_HEIGHT =
  POSITIONS_DRAWER_HEADER_HEIGHT +
  POSITIONS_TABLE_HEADER_HEIGHT +
  POSITIONS_TABLE_ROW_HEIGHT * MIN_POSITIONS_DRAWER_ROWS

// landing page
export const LANDING_PAGE_WIDTH = 1200

// toasts
export const TOAST_DEFAULT_DURATION = 5000

// Mobile carousels
export const CAROUSEL_CARD_WIDTH_MOBILE = 270

// Token/Market icon size
export const MARKET_ICON_SIZE = 28

export const NAV_ELEMENT_ID = 'nav'
